<template>
<Popup width="450px" height="auto">
    <div class="container">
        <div class="content__title">
            Я даю согласие на обработку персональных данных следующим операторам
        </div>
        <AgreementList class="mar-bot" :agreements="agreements" />
        <div class="actions">
            <button class="button"
                    :class="{ disabled: !isValid }"
                    @click="closeWithoutConfirm()">
                Продолжить
            </button>
        </div>
    </div>
</Popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Popup from './Popup.vue'
import AgreementList from '../AgreementList.vue';
import { AgreementsHelper } from '../../model';

export default defineComponent({
    components: {
        AgreementList,
        Popup
    },
    data() {
        return {
            isValid: false
        }
    },
    computed: {
        agreements() {
            return this.$store.getters['agreementsModel/agreementsPopup2'];
        },
        agreementsMap() {
            return this.$store.getters['agreementsModel/agreementsMap'];
        }
    },
    watch: {
        agreementsMap: {
            handler(agreementsMap) {
                const requiredAgreements = AgreementsHelper.buildRequiredAgreements(this.agreements);
                this.isValid = AgreementsHelper.validateAgreements(agreementsMap, requiredAgreements);
            },
            deep: true
        }
    },
    methods: {
        closeWithoutConfirm() {
            if (!this.isValid) {
                return;
            }
            this.$store.dispatch('agreementsModel/updatePopupSettings', {
                code: '',
                agreementCode: ''
            });
        },
    }
})
</script>

<style scoped lang="scss">
.content__link {
    color: inherit;
    border-color: inherit;
}

.mar-bot {
    margin-bottom: 40px;
}

.disabled {
    opacity: 0.3;
    cursor: initial;
}

.container {
    &--big {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.content__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 30px;
}

.actions .button {
    width: 100%;
    justify-content: center;
}
</style>
<style lang="scss" scoped>
::v-deep .agreement {
    margin-bottom: 13px;
}
::v-deep .agreement.title {
    max-width: 680px;

    .title__item {
        margin-left: 0;
    }
}
::v-deep .checkboxParent {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;

    &__children {
        overflow-y: scroll;
        max-height: 300px;
    }
}
</style>
