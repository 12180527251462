class AgreementsHelper {
    public static buildRequiredAgreements(agreements) {
        return agreements.reduce((acc, agreement) => {
            const isCorrectRequired = agreement.type !== 'text' && agreement.req;

            if (agreement?.children?.length > 0) {
                const buildedRequiredAgreements = this.buildRequiredAgreements(agreement?.children);

                if (isCorrectRequired) {
                    const tempConcat = this.concatValues(
                        acc,
                        buildedRequiredAgreements
                    );

                    return this.concatValues(
                        tempConcat,
                        { [agreement.code]: agreement.type === 'radio' ? agreement?.value : true }
                    );
                }

                return this.concatValues(
                    acc,
                    buildedRequiredAgreements
                );
            }

            if (isCorrectRequired) {
                return this.concatValues(
                    acc,
                    { [agreement.code]: agreement.type === 'radio' ? agreement?.value : true }
                )
            }

            return acc;
        }, {});
    }

    static concatValues(oldValues, newValues) {
        const result = { ...oldValues };

        Object.entries(newValues).forEach(([key, value]) => {
            if (result[key] != null) {

                if (Array.isArray(result[key])) {
                    result[key] = [ ...result[key], value ];
                    return;
                }

                result[key] = [ result[key], value ];
                return;
            }

            result[key] = value;
        })

        return result;
    }

    static resolveValue(agreement) {
        switch (agreement.type) {
            case 'parentCheckbox':
            case 'checkbox': {
                return agreement?.defaultSelected;
            }
            case 'radio': {
                return agreement?.defaultSelected ? agreement?.value : null;
            }
            default: {
                return null;
            }
        }
    }

    public static buildAgreementsMap(agreements) {
        return agreements.reduce((acc, agreement) => {
            const value = this.resolveValue(agreement);

            if (agreement?.children?.length > 0) {
                const buildedAgreementsMap = this.buildAgreementsMap(agreement?.children);

                if (value == null) {
                    return {
                        ...acc,
                        ...buildedAgreementsMap
                    };
                }

                return {
                    ...acc,
                    ...buildedAgreementsMap,
                    [agreement.code]: value
                };
            }

            if (value == null) {
                return acc;
            }

            return {
                ...acc,
                [agreement.code]: value
            };
        }, {});
    }

    public static validateAgreements(agreements, requiredAgreements) {
        return Object.entries(requiredAgreements).every(([requiredCode, requiredValue]) => {
            if (agreements[requiredCode] == null) {
                return false;
            }

            if (Array.isArray(requiredValue)) {
                return requiredValue.includes(agreements[requiredCode])
            }

            return agreements[requiredCode] === requiredValue;
        });
    }

    public static findAgreementByFilter(agreements, filter) {
        for (const agreement of agreements) {
            const isUnresolvedFilter = Object.entries(filter).some(([key, value]) => {
                return agreement[key] !== value;
            });

            if (!isUnresolvedFilter) {
                return agreement;
            }

            if (agreement?.children?.length > 0) {
                const foundedAgreement = this.findAgreementByFilter(agreement?.children, filter);
                if (foundedAgreement != null) {
                    return foundedAgreement;
                }
            }
        }

        return null;
    }

    public static findAgreementPopup(agreements, code) {
        for (const agreement of agreements) {
            if (agreement.code == code) {
                return agreement?.children ?? [];
            }

            if (agreement?.children?.length > 0) {
                const foundedAgreement = this.findAgreementPopup(agreement?.children, code);

                if (foundedAgreement != null) {
                    return foundedAgreement;
                }
            }
        }

        return [];
    }

}

export { AgreementsHelper };
